import { Component, OnInit } from '@angular/core';

import { ScheduleService } from './schedule.service';
import { ScheduledJob } from './schedule.contracts';
import { MuseTurnstilesComponentsContext } from '@mufc-aon/muse-turnstiles-components';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'schedule-overview',
  templateUrl: './schedule-overview.component.html',
  styleUrls: ['./schedule-overview.component.scss']
})
export class ScheduleOverviewComponent implements OnInit {
  muse = MuseTurnstilesComponentsContext.instance;
  onAuthSubscription: Subscription;

  timer: number;
  jobs: ScheduledJob[];

  constructor(
    readonly schedule: ScheduleService) {
  }

  ngOnInit(): void {

    this.onAuthSubscription = this.muse.auth.userSession$.pipe(
      tap(userSession => {

        if (this.timer) {
          window.clearTimeout(this.timer)
          this.timer = null;
        }

        if (userSession) this.load();
      })
    )
      .subscribe();
  }

  load(): void {

    this.schedule.load()
      .subscribe(jobs => {

        this.jobs = jobs;

      });

    this.timer = window.setTimeout(this.load.bind(this), 5000);
  }

  ngOnDestroy(): void {

    this.onAuthSubscription.unsubscribe();
  }
}
