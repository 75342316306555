import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { HttpParamsBuilder } from '../shared/http/httpParamsBuilder.service';

import { MatricesSettings } from './matrices.settings';
import { IMatricesSearch, IMatrixPlayerRatingsSearch } from './matrices.contracts';
import { Matrix, MatrixFactory } from './matrix.contract';
import { MatrixPlayerRating, MatrixPlayerRatingFactory} from './player-rating/matrix-player-rating.contract';

const URL = '/matrices';
const RATINGS_URL = URL + '/ratings';

@Injectable()
export class MatricesService {

  constructor(
    @Inject('MatricesSettings') private readonly settings: MatricesSettings,
    private readonly http: HttpClient,
    private readonly httpParamsBuilder: HttpParamsBuilder,
    private readonly matrixFactory: MatrixFactory,
    private readonly matrixPlayerRatingFactory: MatrixPlayerRatingFactory
  ) {
  }

  search(model: IMatricesSearch): Promise<Matrix[]> {

    const params = this.httpParamsBuilder.build(model, { dateFormat: 'yyyy-MM-dd' });

    return this.http
      .get<any>(
        this.settings.ApiRoot + URL,
        {
          params: params
        }
      )
      .toPromise()
      .then(data => {

        return data.map(d => this.matrixFactory.create(d));
      });
  }

  searchRatings(model: IMatrixPlayerRatingsSearch): Promise<MatrixPlayerRating[]> {

    const params = this.httpParamsBuilder.build(model, { dateFormat: 'yyyy-MM-dd' });

    return this.http
      .get<any>(
        this.settings.ApiRoot + RATINGS_URL,
        {
          params: params
        }
      )
      .toPromise()
      .then(data => {

        return data.map(d => this.matrixPlayerRatingFactory.create(d));
      });
  }
}
