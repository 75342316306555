import { JobDescription } from '../scouts/scouts.contracts';

export interface IMatricesSearch {

  from?: Date;
  to?: Date;
  ids?: number[];
  areaIds?: number[];
  scoutIds?: number[];
  scoutIsActive?: boolean;
  latestByScout?: boolean;
  index?: number;
  count?: number;
  jobDescription: JobDescription;
  gender: Genders;
}

export interface IMatrixPlayerRatingsSearch {
  from?: Date;
  to?: Date;
  areaIds?: number[];
  scoutIds?: number[];
  scoutIsActive?: boolean;
  jobDescription: JobDescription;
}

export enum Genders {
  men,
  women
}
