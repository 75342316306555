import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'playerFoot' })
export class PlayerFootPipe implements PipeTransform {

  transform(value) {

    switch (value) {
      default: return '';
      case 0: return 'L';
      case 1: return 'R';
      case 2: return 'B';
    }
  }
}
