import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { ScoutsService } from '../scouts/scouts.service';
import { Scout } from './scouts.contracts';

@Component({
  selector: 'muse-scout',
  templateUrl: './scout.component.html',
  styleUrls: ['./scout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScoutComponent implements OnChanges {

  constructor(
    readonly scoutsService: ScoutsService) {
  }

  @Input()
  id: number;

  @Input()
  model: Scout;

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['id'])
      this.scoutsService.getAllById()
        .then(data => {

          this.model = data[this.id];
        });
  }
}
