import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

import { SharedSettings } from './shared.settings';

@Injectable()
export class Format {

  constructor(
    @Inject('SharedSettings') private readonly settings: SharedSettings,
    private readonly datePipe: DatePipe) {
  }

  date(value: any, format?: string): string {

    return this.datePipe.transform(value, format || this.settings.dateFormat);
  }
}
