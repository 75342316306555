
export function getFullName(this: PersonName): string {

  return [this.first, this.middle, this.last]
    .filter(n => n && n.trim().length)
    .map(n => n.trim())
    .join(' ');
}

export function matchesName(name: PersonName, text: string): boolean {

  if (!text || !text.length) return true;
  text = text.toLowerCase();

  const fullName = name.getFullName().toLowerCase();

  return fullName.startsWith(text) ||
    fullName.indexOf(` ${text}`) >= 0;
}

export class PersonName {

  first: string;
  middle: string;
  last: string;
  getFullName: (this: PersonName) => string = getFullName;
}
