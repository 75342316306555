import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Matrix } from '../matrix.contract';
import { Format } from '../../shared/format';

@Component({
  selector: 'muse-matrix-state',
  templateUrl: './matrix-state.component.html',
  styleUrls: ['./matrix-state.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatrixStateComponent {

  constructor(
    private readonly format: Format
  ) {
  }

  @Input()
  model: Matrix;

}
