import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AreasModule } from '../areas/areas.module';
import { PlayersFactory } from './players.contracts';
import { PlayerFootPipe } from './players.pipes';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([], {}),
    SharedModule,
    AreasModule
  ],
  exports: [
  ],
  declarations: [
    PlayerFootPipe
  ],
  providers: []
})
export class PlayersModule {

  static forRoot(): ModuleWithProviders<PlayersModule> {

    return {
      ngModule: PlayersModule,
      providers: [
        PlayersFactory, PlayerFootPipe
      ]
    };
  }
}
