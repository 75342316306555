import { Injectable, Injector } from '@angular/core';

import { PropertyService } from '../shared/services/property.service';

import { ScoutsService } from '../scouts/scouts.service';
import { Scout } from '../scouts/scouts.contracts';

import { MatrixPlayer } from './players/matrix-player.contract';
import { MatrixPosition, MatrixPositionFactory } from './positions/matrix-position.contract';
import { MatrixPlayerFactory } from './players/matrix-player.contract';

export class Matrix {
  id: number;
  createdOn: Date;
  completedOn?: Date;
  publishedOn?: Date;
  scoutId: number;
  scout: Scout;
  players: MatrixPlayer[];
  positions: MatrixPosition[];
}

@Injectable()
export class MatrixFactory {

  constructor(
    private readonly property: PropertyService,
    private readonly injector: Injector,
    private readonly matrixPositionFactory: MatrixPositionFactory,
    private readonly matrixPlayerFactory: MatrixPlayerFactory
  ) {

  }

  private scoutsService: ScoutsService;

  create(data): Matrix {
    this.locateServices();

    const model = Object.assign(new Matrix(),
      data,
      {
        createdOn: new Date(data.createdOn),
        completedOn: data.completedOn && new Date(data.completedOn),
        publishedOn: data.publishedOn && new Date(data.publishedOn),
        players: data.players.map(d => this.matrixPlayerFactory.create(d))
      });

    this.property.dependantById(model, 'scout', 'scoutId', id => this.scoutsService.itemsById[id]);
    this.property.dependant(model, m => m.players, 'positions', value => this.getPositions(value));

    return model;
  }

  private getPositions(
    value: MatrixPlayer[]
  ): MatrixPosition[] {

    if (!value) return null;

    const postitions = value.reduce((a, matrixPlayer) => {

        var position: MatrixPosition =
          a.find(cand => cand.definitionId === matrixPlayer.rating.positionDefinitionId);

        if (!position) {
          position = this.matrixPositionFactory.create({
            definitionId: matrixPlayer.rating.positionDefinitionId,
            players: []
          });

          a.push(position);
        }

        position.players.push(matrixPlayer);

        return a;
      },
      []);

    postitions.sort((a, b) => a.definition.sequence - b.definition.sequence);

    return postitions;
  }

  locateServices() {
    if (this.scoutsService) return;

    this.scoutsService = this.injector.get(ScoutsService);
  }
}
