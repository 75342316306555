import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import * as XLSX from 'xlsx';
import { State } from '../shared/state';
import { Format } from '../shared/format';

import { Matrix } from './matrix.contract';
import { MatrixPlayer } from './players/matrix-player.contract';
import { MatrixPlayerRating } from './player-rating/matrix-player-rating.contract';

import { MatricesService } from './matrices.service';
import { MatricesExportService } from './matrices.export.service';
import { MatrixPositionDefinitionsService } from './position-definitions/matrix-position-definitions.service';

import { JobDescription } from '../scouts/scouts.contracts';
import { MuseTurnstilesComponentsContext } from '@mufc-aon/muse-turnstiles-components';
import { tap } from 'rxjs/operators';
import { Genders } from './matrices.contracts';

@Component({
  selector: 'muse-matrices',
  templateUrl: './matrices.component.html',
  styleUrls: ['./matrices.component.scss']
})
export class MatricesComponent implements OnDestroy {
  muse = MuseTurnstilesComponentsContext.instance;
  onAuthSubscription: Subscription;

  constructor(
    readonly state: State,
    private readonly format: Format,
    private readonly matricesService: MatricesService,
    private readonly matricesExportService: MatricesExportService,
    private readonly matrixPositionDefinitionsService: MatrixPositionDefinitionsService
  ) {

    this.onAuthSubscription = this.muse.auth.userSession$.pipe(
      tap(userSession => {

        if (userSession == null) {
          this.matrices = [];
          return;
        }

        if (!this.reportYears?.length) {
          const now = new Date();
          const yearNow = now.getFullYear();
          const monthNow = now.getMonth();

          this.reportMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            .map(month => new Month(month, new Date(yearNow, month, 1).toLocaleString("en-gb", { month: "long" })));

          this.reportYears = this.getReportYears();
          this.reportYear = yearNow;
          this.reportMonth = monthNow;

          this.load();

          this.matrixPositionDefinitionsService.getAllById();
        }
      })

      )
      .subscribe();
  }

  scoutId: number;
  searchText: string;

  reportDate: Date;
  reportYear: number;
  reportMonth: number;
  reportMonths: Month[];
  reportYears: number[];
  matrices: Matrix[];
  ratings: MatrixPlayerRating[];
  ratingsByPlayer: { [playerId: number]: MatrixPlayerRating[] } = {};

  selectedMatrixPlayer: MatrixPlayer;
  scoutType = JobDescription.firstTeamScout;
  gender = Genders.men;

  exportCsv(): void {

    this.state.spin(() => {

      const filename = `matrices-${this.format.date(this.reportDate)}.xlsx`;

      return this.matricesExportService
        .getData(this.matrices, this.scoutType)
        .then(data => {

          const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

          XLSX.writeFile(workbook, filename);

        });
    });
  };

  private applyRatings(lastYear: Date): void {

    this.ratings.forEach(playerRating => {

      var byPlayer = this.ratingsByPlayer[playerRating.playerId] || (this.ratingsByPlayer[playerRating.playerId] = []);
      byPlayer.push(playerRating);

      var matrixPlayer = this.findPlayer(
        playerRating.scoutId,
        playerRating.positionDefinitionId,
        playerRating.playerId);

      if (matrixPlayer) {

        const ratingIndexByDate =
          this.getMonthIndex(lastYear, new Date(playerRating.on));
        matrixPlayer.ratingsLastYear[ratingIndexByDate] = playerRating;
        matrixPlayer.ratings.push(playerRating);
      }
    });

    this.matrices.forEach(matrix => {

      matrix.players.forEach(matrixPlayer => {

        const ratings = this.ratingsByPlayer[matrixPlayer.player.id] || [];
        matrixPlayer.player.ratings = ratings;
        matrixPlayer.ratingsFromOtherScouts = ratings.filter(rating => rating.scoutId !== matrix.scoutId);
      });
    });
  }

  private getMonthIndex(from, to) {

    return (to.getFullYear() - from.getFullYear()) * 12 +
      to.getMonth() -
      from.getMonth();
  }

  private findPlayer(scoutId: number, positionDefinitionId: number, playerId: number): MatrixPlayer {

    const matrix = this.matrices
      .find(i => i.scoutId === scoutId);

    if (matrix) {

      return matrix.players.find(matrixPlayer =>
        matrixPlayer.rating.positionDefinitionId === positionDefinitionId &&
        matrixPlayer.player.id === playerId);
    }

    return null;
  }

  private getUniqueScoutIds(matrices: Matrix[]) {

    return matrices
      .reduce((a, matrix) => {

        if (a.indexOf(matrix.scoutId))
          a.push(matrix.scoutId);

        return a;
      },
        []);
  }

  private getReportYears() {
    let currentYear = new Date().getFullYear();
    let years: number[] = [];
    let startYear: number = 2017;

    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    return years;
  }

  load() {
    this.state.spin();

    this.reportDate = new Date(this.reportYear, this.reportMonth, 1);

    const nextMonth = new Date(this.reportDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    const lastYear = new Date(nextMonth);
    lastYear.setFullYear(lastYear.getFullYear() - 1);

    this.matricesService.search({
      from: this.reportDate,
      to: nextMonth,
      scoutIsActive: true,
      count: 5000,
      jobDescription: this.scoutType,
      gender: this.gender
    })
      .then(matrices => {

        this.matrices = matrices;

        return this.matricesService.searchRatings({
          from: lastYear,
          to: nextMonth,
          scoutIds: this.getUniqueScoutIds(matrices),
          scoutIsActive: true,
          jobDescription: this.scoutType
        })
          .then(ratings => {

            this.ratings = ratings;

            this.applyRatings(lastYear);
          });
      })
      .finally(() => this.state.done());
  }

  selectScoutId(scoutId: number) {

    this.scoutId = this.scoutId === scoutId
      ? null
      : scoutId;
  }

  ngOnDestroy(): void {

    this.onAuthSubscription.unsubscribe();
  }
}

export class Month {

  constructor(monthId: number, monthName: string) {
    this.id = monthId;
    this.name = monthName;
  }

  id: number;
  name: string;
}
