import { Injectable } from '@angular/core';

import { PropertyService } from '../../shared/services/property.service';

import { MatrixPlayerRating, MatrixPlayerRatingFactory } from '../player-rating/matrix-player-rating.contract';
import { Player, PlayersFactory } from '../../players/players.contracts';

export class MatrixPlayer {
  player: Player;
  rating: MatrixPlayerRating;
  ratings: MatrixPlayerRating[] = [];
  ratingsLastYear: MatrixPlayerRating[] = [null, null, null, null, null, null, null, null, null, null, null, null];
  ratingsFromOtherScouts: MatrixPlayerRating[] = [];
  getMovement: (MatrixPlayerRating) => number = getMatrixPlayerMovement;
  currentSeasonStatistics: MatrixPlayerStatistics;
  careerFirstTeamStatistics: MatrixPlayerStatistics;
  careerInternationalStatistics: MatrixPlayerStatistics;
}

export class AgeGroup {
  name: string;
  ageFrom: number;
  ageTo: number;
}

@Injectable()
export class MatrixPlayerFactory {

  constructor(
    private readonly property: PropertyService,
    private readonly playersFactory: PlayersFactory,
    private readonly ratingFactory: MatrixPlayerRatingFactory
  ) {
  }

  create(data): MatrixPlayer {

    const model = Object.assign(new MatrixPlayer(),
      data,
      {
        player: this.playersFactory.create(data.player),
        rating: this.ratingFactory.create(data.rating)
      });

    return model;
  }
}

export function getMatrixPlayerMovement(
  this: MatrixPlayer, rating: MatrixPlayerRating): number {

  const index = this.ratingsLastYear && this.ratingsLastYear.indexOf(rating);
  if (index < 1) return null;

  let previousRating;
  for (var i = index - 1; i > 0; i--) {
    previousRating = this.ratingsLastYear[i];
    if (previousRating) break;
  }
  if (!previousRating) return null;
  if (previousRating.level == null) return null;

  return previousRating.sequence - rating.sequence;
}

export class MatrixPlayerStatistics {
  appearances: number;
  starts: number;
  minutesPlayed: number;
}
