import { Injectable } from '@angular/core';

import { Format } from '../shared/format';
import { Matrix } from './matrix.contract';
import { MatrixPlayerLevelPipe } from './matrices.pipes';
import { MatrixPosition } from './positions/matrix-position.contract';
import { MatrixPlayer } from './players/matrix-player.contract';
import { PlayerFootPipe } from '../players/players.pipes';
import { AgeGroup } from './players/matrix-player.contract';

import { JobDescription } from '../scouts/scouts.contracts';

@Injectable()
export class MatricesExportService {

  constructor(
    private readonly format: Format,
    private readonly formatPlayerLevel: MatrixPlayerLevelPipe,
    private readonly formatPlayerFoot: PlayerFootPipe
  ) {
  }

  getData(matrices: Matrix[], scoutType: JobDescription): Promise<any[]> {

    const data: any[] = [];
    const jobs: Promise<void>[] = [];

    data.push(this.commonHeaders.concat(scoutType == (JobDescription.academyScout || JobDescription.partTimeAcademyScout) ? this.academyHeaders : this.firstTeamHeaders).concat(this.commonHeadersEnd));

    matrices.forEach(matrix => {

      jobs.push(this.getMatrixData(matrix, data, scoutType));
    });

    return Promise.all(jobs)
      .then(() => data);
  }

  private getMatrixData(matrix: Matrix, data: any[], scoutType: JobDescription): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      setTimeout(() => {
        try {
          matrix.positions.forEach(position => {

            position.players.forEach(matrixPlayer => {

              this.getPlayerData(matrix, position, matrixPlayer, data, scoutType);
            });
          });

          resolve();

        } catch (err) {

          reject(err);
        }
      });
    });
  }

  private getPlayerData(
    matrix: Matrix,
    position: MatrixPosition,
    matrixPlayer: MatrixPlayer, data: any[],
    scoutType: JobDescription) {

    const team = matrixPlayer.player.team;
    const parentTeam = matrixPlayer.player.parentTeam;

    const row = [
      matrix.scout.name.getFullName(),
      'ALL',
      position.definition.abbreviation,
      matrixPlayer.rating.sequence,
      matrixPlayer.player.name.getFullName(),
      matrixPlayer.player.birthArea && matrixPlayer.player.birthArea.name,
      matrixPlayer.rating.age,
      this.formatPlayerFoot.transform(matrixPlayer.player.foot),
      team && team.name,
      team && team.area && team.area.name
    ];

    const currentMonth = matrix.createdOn.getMonth();
    for (let month = 0; month < 12; month++) {
      let index = month + 5 - currentMonth;
      while (index < 0) index += 12;
      while (index > 11) index -= 12;

      const rating = matrixPlayer.ratingsLastYear[index];

      row.push(
        rating && rating.level !== null
          ? this.formatPlayerLevel.transform(rating.level, matrix.scout)
          : '-'
      );
    }

    row.push(
      matrixPlayer.player.contractExpiresOn
        ? this.format.date(matrixPlayer.player.contractExpiresOn)
        : '-'
    );

    row.push(matrixPlayer.rating.yes ? 'Yes' : '');
    row.push(matrixPlayer.player.id);
    row.push(matrixPlayer.player.optaCoreId);

    // movement since last matrix
    const movement = matrixPlayer.getMovement(matrixPlayer.ratingsLastYear[11]);
    row.push(movement === null ? 'new' : movement);

    // team optaCoreId
    row.push(team && team.optaCoreId);

    // on other matrices
    row.push(matrixPlayer.ratingsFromOtherScouts.length > 0 ? 'yes' : 'no');

    // total number of times on a matrix in other positions
    row.push((matrixPlayer.ratingsFromOtherScouts.filter(r => r.positionDefinitionId === position.definitionId).length).toString());

    // number of other scouts having this player
    row.push(matrixPlayer.ratingsFromOtherScouts.reduce((scoutIds, rating) => {
      if (scoutIds.indexOf(rating.scoutId) === -1)
        scoutIds.push(rating.scoutId);

      return scoutIds;
    },
      []).length.toString());

    // team is national
    row.push(team && team.isInternational ? 'yes' : 'no');

    row.push(matrixPlayer.currentSeasonStatistics.appearances);
    row.push(matrixPlayer.currentSeasonStatistics.starts);
    row.push(matrixPlayer.currentSeasonStatistics.minutesPlayed);
    row.push(matrixPlayer.careerFirstTeamStatistics.appearances);
    row.push(matrixPlayer.careerFirstTeamStatistics.starts);
    row.push(matrixPlayer.careerFirstTeamStatistics.minutesPlayed);
    row.push(matrixPlayer.careerInternationalStatistics.appearances);
    row.push(matrixPlayer.careerInternationalStatistics.starts);
    row.push(matrixPlayer.careerInternationalStatistics.minutesPlayed);

    row.push(matrixPlayer.rating.playerNote || "");
    row.push(matrixPlayer.player.buyoutFee > 0 ? matrixPlayer.player.buyoutFee : "");
    row.push(matrixPlayer.player.transferComments || "");

    if (scoutType == JobDescription.firstTeamScout) {
      if (matrixPlayer.rating.betterThanOrEqualTo !== null) {
        row.push(matrixPlayer.rating.betterThanOrEqualTo.name.getFullName());
      } else {
        row.push("");
      }

      if (matrixPlayer.rating.betterThanOrEqualToSecond !== null) {
        row.push(matrixPlayer.rating.betterThanOrEqualToSecond.name.getFullName());
      } else {
        row.push("");
      }
     
      row.push(matrixPlayer.rating.positionalDescriptor);
      row.push(matrixPlayer.rating.yaDevelopmentTime);
      row.push(matrixPlayer.rating.isInjured ? 'Yes' : 'No');
      row.push(parentTeam != null ? parentTeam.name : "");
      row.push(this.format.date(matrixPlayer.rating.playerNoteCreated));
    }

    if (scoutType == (JobDescription.academyScout || JobDescription.partTimeAcademyScout)) {
      row.push(matrixPlayer.rating.unattainable ? 'Yes' : 'No')

      let dateNow = new Date();
      let yearNow = dateNow.getFullYear();
      // Academy age groups still run from Sep - Aug, but Academy age groups will show changes from July.
      let endOfAcademicYearDate = new Date(dateNow.getMonth() > 5 ? yearNow + 1 : yearNow, 7, 31);
      let ageEndOfAcademicYear = this.getAgeOn(matrixPlayer.player.dateOfBirth, endOfAcademicYearDate);

      let ageGroup = this.academyAgeGroups.find(a => ageEndOfAcademicYear >= a.ageFrom && ageEndOfAcademicYear <= a.ageTo);
      row.push(ageGroup ? ageGroup.name : '21+');
    }

    row.push(this.format.date(matrix.publishedOn));

    let optaPlayerName = matrixPlayer.player.optaName.getFullName();
    row.push(optaPlayerName);
    row.push(!optaPlayerName ? matrixPlayer.player.name.getFullName() : optaPlayerName);

    row.push(matrixPlayer.player.museId?.toUpperCase());
    row.push(matrixPlayer.player.teamMuseId?.toUpperCase());
    row.push(matrixPlayer.player.parentTeamMuseId?.toUpperCase());
 
    data.push(row);
  }

  private getAgeOn(dateOfBirth: Date, on: Date): number {
    var age = on.getFullYear() - dateOfBirth.getFullYear();
    var m = on.getMonth() - dateOfBirth.getMonth();
    if (m < 0 || (m === 0 && on.getDate() < dateOfBirth.getDate())) {
      age--;
    }
    return age;
}

  private readonly commonHeaders: string[] = [
    'Scout Name',
    'All',
    'Position',
    'Sequence',
    'Player Name',
    'Birth Nationality',
    'Age',
    'Foot',
    'Team Name',
    'Team Area',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Contract Expires',
    'Yes',
    'Player TMan Id',
    'Player Opta Id',
    'Movement',
    'Team Opta Id',
    'Rated by other scout (yes / no)',
    'Total number of times on a matrix (any scout)',
    'Number of other scouts having this player',
    'National Team',
    'Current Season Appearances',
    'Current Season Starts',
    'Current Season Minutes Played',
    'Career First Team Appearances',
    'Career First Team Starts',
    'Career First Team Minutes Played',
    'Career International Appearances',
    'Career International Starts',
    'Career International Minutes Played',
    'Player Note',
    'Buyout Fee',
    'Transfer Comments'
  ];

  private readonly firstTeamHeaders: string[] = [
    'Better Than or Equal to',
    'Better Than or Equal to Second',
    'Positional Descriptor',
    'YA Development Time',
    'Injured',
    'Parent Team Name',
    'Player Note Created'
  ];

  private readonly academyHeaders: string[] = [
    'Unattainable',
    'Age Group'
  ];

  private readonly commonHeadersEnd: string[] = [
    'Published',
    'Opta Player Name',
    'Opta Player Name (Tman if blank)',
    'Player MuseId',
    'Team MuseId',
    'Parent Team MuseId'
  ];

  private readonly academyAgeGroups: AgeGroup[] = [
    { name: 'U21', ageFrom: 19, ageTo: 21},
    { name: 'U18/U17', ageFrom: 17, ageTo: 18 },
    { name: 'U16', ageFrom: 16, ageTo: 16 },
    { name: 'U15', ageFrom: 15, ageTo: 15 },
    { name: 'U14/U13', ageFrom: 13, ageTo: 14 },
    { name: 'U12', ageFrom: 1, ageTo: 12 }
  ]
}
