import { NgModule, ModuleWithProviders, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SharedSettings } from './shared.settings';
import { Format } from './format';
import { State } from './state';
import { HttpParamsBuilder } from './http/httpParamsBuilder.service';
import { PropertyService } from './services/property.service';

import { PersonNameComponent } from './people/person-name.component';
import { ClickOutDirective } from './events/click-out.directive';
import { SwipeDirective } from './events/swipe.directive';
import { CardsDirective } from './cards/cards.directive';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
    PersonNameComponent,
    ClickOutDirective, SwipeDirective,
    CardsDirective
  ],
  declarations: [
    PersonNameComponent,
    ClickOutDirective, SwipeDirective,
    CardsDirective
  ]
})
export class SharedModule {

  static forRoot(settings: SharedSettings): ModuleWithProviders<SharedModule> {

    return {
      ngModule: SharedModule,
      providers: [
        { provide: 'SharedSettings', useValue: settings },
        State, Format,
        HttpParamsBuilder,
        PropertyService
      ]
    };
  }
}
