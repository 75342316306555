import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ScheduleService } from './schedule.service';
import { ScheduleSettings } from './schedule.contracts';
import { ScheduleOverviewComponent } from './schedule-overview.component';

const components = [ScheduleOverviewComponent];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    exports: components,
    declarations: components,
    providers: []
})
export class ScheduleModule {

    static forRoot(settings: ScheduleSettings): ModuleWithProviders<ScheduleModule> {

        return {
            ngModule: ScheduleModule,
            providers: [
                ScheduleService,
                { provide: 'ScheduleSettings', useValue: settings }
            ]
        };
    }
}