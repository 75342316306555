import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ScheduleSettings, ScheduledJob } from './schedule.contracts';

const URL = '/schedule';

@Injectable()
export class ScheduleService {

  constructor(
    @Inject('ScheduleSettings') private readonly settings: ScheduleSettings,
    private readonly http: HttpClient
  ) {
  }

  load(): Observable<ScheduledJob[]> {

    return this.http
      .get<ScheduledJob[]>(
        this.settings.ApiRoot + URL
      )
      .pipe(
        map((d: any[]) => {

          return d.map(di => new ScheduledJob(di));
        }));
  }
}
