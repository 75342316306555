import { Component, Input, ViewEncapsulation } from '@angular/core';

import { MatrixPlayerRating } from './matrix-player-rating.contract';
import { JobDescription } from '../../scouts/scouts.contracts';

@Component({
  selector: 'muse-matrix-player-rating',
  templateUrl: './matrix-player-rating.component.html',
  styleUrls: ['./matrix-player-rating.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatrixPlayerRatingComponent {

  @Input()
  model: MatrixPlayerRating;
  jobDescription = JobDescription;

}
