import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { CachingIdService } from '../shared/services/caching-id.service';

import { TeamsSettings } from './teams.settings';
import { Team, TeamsFactory } from './teams.contracts';
import { MuseTurnstilesComponentsContext } from '@mufc-aon/muse-turnstiles-components';
import { tap } from 'rxjs/operators';

const SCOUTS_URL = '/teams';

@Injectable()
export class TeamsService extends CachingIdService<Team> {

  muse = MuseTurnstilesComponentsContext.instance;

  constructor(
    @Inject('TeamsSettings') private readonly settings: TeamsSettings,
    private readonly http: HttpClient,
    private readonly teamsFactory: TeamsFactory
  ) {

    super('teams');

    this.muse.auth.userSession$.pipe(
      tap(userSession => {

        if (userSession == null) {
          this.clear();
          return;
        }

        this.getAll();
      })
    )
      .subscribe();
  }

  protected load(): Promise<Team[]> {

    return this.http
      .get<Team[]>(
        this.settings.ApiRoot + SCOUTS_URL
      )
      .toPromise()
      .then(data => {

        return data.map(d => this.teamsFactory.create(d));
      });
  }
}
