import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AreasService } from './areas.service';
import { Area } from './areas.contracts';

@Component({
  selector: 'muse-area',
  templateUrl: './area.component.html'
})
export class AreaComponent implements OnChanges{

  constructor(
    readonly areaService: AreasService) { }

  @Input()
  id: number;

  model: Area;

  ngOnChanges(changes: SimpleChanges): void {

    this.areaService.getAllById()
      .then(data => {

        this.model = data[this.id];
      });
  }
}
