import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatrixPlayer } from '../players/matrix-player.contract';
import { MatrixPlayerRating } from '../player-rating/matrix-player-rating.contract';
import { Format } from '../../shared/format';

@Component({
  selector: 'muse-matrix-player-popup',
  templateUrl: './matrix-player-popup.component.html',
  styleUrls: ['./matrix-player-popup.component.scss']
})
export class MatrixPlayerComponent {

  width: number = 100;
  height: number = 100;
  viewBox: string = `0 0 ${this.width} ${this.height}`;

  private _model: MatrixPlayer;

  constructor(
    private readonly format: Format) {
  }

  @Output()
  close = new EventEmitter();

  onClose() {

    this.close.emit();
  }

  @Input()
  set model(value: MatrixPlayer) {

    if (this._model === value) return;

    this._model = value;
    this.lines = value ? this.getLines(value) : [];
  };

  get model(): MatrixPlayer { return this._model; }

  grid: GraphRect[] = [];
  lines: GraphPolyLine[] = [];

  showRating(coord: GraphCoord) {

    console.debug(coord);
  }

  private getLines(matrixPlayer: MatrixPlayer): GraphPolyLine[] {

    const lines: { [key: string]: GraphPolyLine } = {};
    let minX: number, maxX: number;

    matrixPlayer.player.ratings
      .sort((a, b) => a.on.getTime() - b.on.getTime())
      .forEach(rating => {

        const key = this.getKey(rating);
        const line = lines[key] || (lines[key] = new GraphPolyLine(`${rating.positionDefinition.name} ${rating.scout.name.getFullName()}`));
        const x = rating.on.getTime();

        if (minX == undefined || x < minX) minX = x;
        if (maxX == undefined || x > maxX) maxX = x;

        line.coords.push({ x: x, y: rating.sequence, rating: rating });
      });

    const actualWidth = maxX - minX;
    const actualHeight = 36;

    const scaleX = this.width / actualWidth;
    const scaleY = this.height / actualHeight;

    var date = new Date(minX);
    date.setDate(1);

    this.grid = [];

    for (
      let x = date.getTime() - minX;
      x < actualWidth;
      x = date.getTime() - minX) {

      this.grid.push({
        x1: x * scaleX,
        y1: -2,
        x2: x * scaleX,
        y2: this.height + 2
      });

      date.setMonth(date.getMonth() + 1);
    }

    for (let y = 0; y < actualHeight; y++) {

      this.grid.push({
        x1: -2,
        y1: y * scaleY,
        x2: this.width + 2,
        y2: y * scaleY
      });
    }

    return Object.keys(lines)
      .map((key, index) => {
        const line = lines[key];
        line.points = line.coords
          .map(coord => {
            coord.x = (coord.x - minX) * scaleX;
            coord.y = (coord.y + index / 15) * scaleY;

            return `${coord.x},${coord.y}`;
          }).join(' ');

        return line;
      });
  }

  private getKey(rating: MatrixPlayerRating): string {

    return `${rating.scoutId}-${rating.positionDefinitionId}`;
  };
}

export class GraphRect {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export class GraphPolyLine {
  constructor(public readonly title: string) { }

  coords: GraphCoord[] = [];
  points: string;
  hide: boolean = false;
}

export class GraphCoord {
  x: number;
  y: number;
  rating: MatrixPlayerRating;
}
