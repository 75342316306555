import { Injectable, Injector } from '@angular/core'

import { PropertyService } from '../shared/services/property.service';
import { Area } from '../areas/areas.contracts';
import { AreasService } from '../areas/areas.service';

export class Team {
  id: number;
  museId: string;
  optaCoreId: number;
  name: string;
  isInternational: boolean;
  areaId: number;
  area: Area;
}

@Injectable()
export class TeamsFactory {

  constructor(
    private readonly property: PropertyService,
    private readonly injector: Injector
  ) {
  }

  private areasService: AreasService;

  create(data): Team {

    this.locateServices();

    const model = Object.assign(new Team(),
      data,
      {
      });

    this.property.dependantById(model, 'area', 'areaId', id => this.areasService.itemsById[id]);

    return model;
  }

  locateServices() {
    if (this.areasService) return;

    this.areasService = this.injector.get(AreasService);
  }
}
