import { Injectable, Injector } from '@angular/core';

import { PersonName } from '../shared/people/person-name';
import { PropertyService } from '../shared/services/property.service';
import { Area } from '../areas/areas.contracts';
import { AreasService } from '../areas/areas.service';
import { Team } from '../teams/teams.contracts';
import { TeamsService } from '../teams/teams.service';
import { MatrixPlayerRating } from '../matrices/player-rating/matrix-player-rating.contract';

export class Player {
  id: number;
  museId: string;
  optaCoreId: number;
  name: PersonName;
  teamId: number;
  parentTeamId: number;
  teamMuseId: string;
  parentTeamMuseId: string;
  team: Team;
  parentTeam: Team;
  contractExpiresOn: Date;
  dateOfBirth: Date;
  birthAreaId: number;
  birthArea: Area;
  foot: number;
  ratings: MatrixPlayerRating[];
  buyoutFee: number;
  transferComments: string;
  optaName: PersonName;
}

@Injectable()
export class PlayersFactory {

  constructor(
    private readonly property: PropertyService,
    private readonly injector: Injector
  ) {
  }

  private areasService: AreasService;
  private teamsService: TeamsService;

  create(data): Player {

    this.locateServices();

    const model = Object.assign(new Player(),
      data,
      {
        name: Object.assign(new PersonName(), data.name),
        contractExpiresOn: data.contractExpiresOn && new Date(data.contractExpiresOn),
        dateOfBirth: new Date(data.dateOfBirth),
        optaName: Object.assign(new PersonName(), data.optaName)
      });

    this.property.dependantById(model, 'birthArea', 'birthAreaId', id => this.areasService.itemsById[id]);
    this.property.dependantById(model, 'team', 'teamId', id => this.teamsService.itemsById[id]);
    this.property.dependantById(model, 'parentTeam', 'parentTeamId', id => this.teamsService.itemsById[id]);

    return model;
  }

  locateServices() {
    if (this.areasService) return;

    this.areasService = this.injector.get(AreasService);
    this.teamsService = this.injector.get(TeamsService);
  }
}
