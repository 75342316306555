import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { MuseTurnstilesComponentsContext } from '@mufc-aon/muse-turnstiles-components';

const USER_TOKEN_HEADER = 'user-token';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  muse = MuseTurnstilesComponentsContext.instance;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let headers = req.headers;

    const userToken = this.muse.auth.userSession?.token;
    if (userToken)
      headers = req.headers.set(USER_TOKEN_HEADER, userToken);

    return next
      .handle(req.clone({ headers: headers }));
  }
}
