import { Directive, ElementRef, AfterViewChecked, NgZone, Renderer2 } from '@angular/core';

@Directive({
    selector: '[cards]',
    host: {
      '(window:resize)': 'adjust()',
      'style':'position:relative'
    }
})
export class CardsDirective implements AfterViewChecked {

    constructor(
        private zone: NgZone,
        public renderer: Renderer2,
        public element: ElementRef) {

        this.el = this.element.nativeElement;
        this.renderer.addClass(this.el, 'cards');
    }

    private el;

    ngAfterViewChecked(): void {

        this.adjust();
    }

    adjust(): void {

        const columns = {};

        const cells = Array.from<HTMLElement>(this.el.children);

        if (!cells.length) return;

        this.zone.runOutsideAngular(() => {

            requestAnimationFrame(() => {

                cells.forEach(cell => {
                    var dim = {
                        left: cell.offsetLeft,
                        top: cell.offsetTop,
                        height: cell.offsetHeight
                    };

                    var column = `_${dim.left}`;
                    if (!columns[column]) columns[column] = 0;

                    var currentOffset = parseInt(cell.style.marginTop) || 0;
                    var newOffset = (columns[column] - dim.top + currentOffset) + 'px';

                    if (columns[column] !== dim.top &&
                        cell.style.marginTop !== newOffset) {

                        this.renderer.setStyle(cell, 'margin-top', newOffset);
                    }

                    columns[column] += dim.height;
                });
            });
        });
    }
}
