import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AreasModule } from '../areas/areas.module';
import { ScoutsModule } from '../scouts/scouts.module';
import { TeamsModule } from '../teams/teams.module';

import { MatricesSettings } from './matrices.settings';
import { MatricesComponent } from './matrices.component';
import { MatrixStateComponent } from './state/matrix-state.component';
import { MatrixPlayerComponent } from './player-popup/matrix-player-popup.component';
import { MatrixPlayerRatingComponent } from './player-rating/matrix-player-rating.component';
import { MatricesService } from './matrices.service';
import { MatrixPositionDefinitionsService } from './position-definitions/matrix-position-definitions.service';
import { MatrixPositionDefinitionComponent } from './position-definitions/matrix-position-definition.component';
import { MatrixSearchFilterPipe } from './matrices.pipes';
import { MatrixFactory } from './matrix.contract';
import { MatricesExportService } from './matrices.export.service';
import { MatrixPlayerFactory } from './players/matrix-player.contract';
import { MatrixPositionFactory } from './positions/matrix-position.contract';
import { MatrixPlayerRatingFactory } from './player-rating/matrix-player-rating.contract';
import { MatrixPlayerLevelPipe } from './matrices.pipes';

const ROUTES = [
  { path: 'matrices', component: MatricesComponent }
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(ROUTES, {}),
    SharedModule,
    AreasModule,
    ScoutsModule,
    TeamsModule
  ],
  exports: [
  ],
  declarations: [
    MatricesComponent, MatrixStateComponent,
    MatrixPlayerComponent, MatrixPlayerRatingComponent, MatrixPlayerLevelPipe,
    MatrixPositionDefinitionComponent,
    MatrixSearchFilterPipe
  ],
  providers: []
})
export class MatricesModule {

  static forRoot(settings: MatricesSettings): ModuleWithProviders<MatricesModule> {

    return {
      ngModule: MatricesModule,
      providers: [
        MatricesService,
        MatrixFactory, MatrixPlayerFactory, MatrixPositionFactory,
        MatrixPlayerRatingFactory, MatrixPlayerLevelPipe,
        MatricesExportService,
        MatrixSearchFilterPipe,
        MatrixPositionDefinitionsService,
        { provide: 'MatricesSettings', useValue: settings }
      ]
    };
  }
}
