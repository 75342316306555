import { ChangeDetectorRef, Component, Output } from '@angular/core';
import { MuseTurnstilesComponentsContext } from '@mufc-aon/muse-turnstiles-components';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  muse = MuseTurnstilesComponentsContext.instance;
  onAuthSubscription: Subscription;

  isLoggedIn: boolean = false;

  constructor(
    private change: ChangeDetectorRef
  ) {  }

  ngOnInit(): void {
    this.onAuthSubscription = this.muse.auth.userSession$.pipe(
      tap(userSession => {

        this.isLoggedIn = userSession != null;
        this.change.detectChanges();
      })
    )
      .subscribe();
  }

  ngOnDestroy(): void {

    this.onAuthSubscription.unsubscribe();
  }
}
