export abstract class CachingIdService<T extends { id: number }> {

  items: T[];
  itemsById: { [id: number]: T } = {};
  private promise: Promise<T[]>;

  constructor(
    protected readonly name) {
  }

  protected abstract load(): Promise<T[]>;

  protected clear(): void {

    this.items = [];
    this.itemsById = {};
    this.promise = null;
  }

  private loadAndMap(): Promise<T[]> {

    return this.load()
      .then(items => {

        this.items = items;
        this.itemsById = items.reduce(
          (map: { [id: number]: T }, item) => {
            map[item.id] = item;
            return map;
          },
          {});

        return items;
      });
  }

  getAll(): Promise<T[]> {

    return this.promise || (this.promise = this.loadAndMap());
  }

  getAllById(): Promise<{ [id: number]: T }> {

    return this.getAll()
      .then(() => {

        return this.itemsById;
      });
  }
}
