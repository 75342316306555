import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './navmenu/navmenu.component';
import { HomeComponent } from './home/home.component';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AreasModule } from './areas/areas.module';
import { MatricesModule } from './matrices/matrices.module';
import { PlayersModule } from './players/players.module';
import { ScoutsModule } from './scouts/scouts.module';
import { TeamsModule } from './teams/teams.module';
import { ScheduleModule } from './schedule/schedule.module';

const API_ROOT_URL = '/api';
const REPORTAGE_API_ROOT_URL = API_ROOT_URL + '/reportage';

const ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(ROUTES, {
    useHash: true, enableTracing: false
}),
    SharedModule.forRoot({ dateFormat: 'dd-MMM-yy' }),
    AuthModule.forRoot(),
    AreasModule.forRoot({ ApiRoot: REPORTAGE_API_ROOT_URL }),
    MatricesModule.forRoot({ ApiRoot: REPORTAGE_API_ROOT_URL }),
    PlayersModule.forRoot(),
    ScoutsModule.forRoot({ ApiRoot: REPORTAGE_API_ROOT_URL }),
    TeamsModule.forRoot({ ApiRoot: REPORTAGE_API_ROOT_URL }),
    ScheduleModule.forRoot({ ApiRoot: REPORTAGE_API_ROOT_URL })
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
