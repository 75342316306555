import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


import { defineCustomElements as defineCore } from '@mufc-aon/muse-core-components/loader';
import { defineCustomElements as defineTurnstiles } from '@mufc-aon/muse-turnstiles-components/loader';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

defineCore();
defineTurnstiles();
