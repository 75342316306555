import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Injectable()
export class HttpParamsBuilder {

  constructor(
    private readonly datePipe: DatePipe,
  ) {
  }

  public build<T>(
    obj: T,
    options?: { dateFormat: string }
  ): HttpParams {

    const params = new HttpParams();

    if (obj == null) return params;

    // defaults when no options
    const format = {
      date: (value: Date): string => {

        return value.toISOString();
      }
    };

    if (options) {
      // override defaults
      if (options.dateFormat)
        format.date = value => {
          return this.datePipe.transform(value, options.dateFormat);
        };
    }

    return this.setValue(params, '', obj, false, format);
  }

  private setArray<T>(
    params: HttpParams, prefix: string, valueArray: Array<T>,
    format: { date(value: Date): string }
  ): HttpParams {

    valueArray.forEach(value => {
      params = this.setValue(params, prefix, value, true, format);
    });

    return params;
  }

  private setObject<T>(
    params: HttpParams, prefix: string, objectValue: T,
    format: { date(value: Date): string }
  ): HttpParams {

    const objectKeys = Object.keys(objectValue) as Array<keyof T>;

    // use dot notation for properties
    if (prefix) prefix = prefix + '.';

    for (let objectKey of objectKeys) {

      const key = prefix + objectKey.toString();

      params = this.setValue(params, key, objectValue[objectKey], false, format);
    }

    return params;
  }

  private setValue<T>(
    params: HttpParams, key: string, value: any, append: boolean,
    format: { date(value: Date): string }
  ): HttpParams {

    if (value instanceof Array) {

      return this.setArray(params, key, value, format);

    } else if (value instanceof Date) {

      value = format.date(value);

    } else if (value instanceof Object) {

      return this.setObject(params, key, value, format);

    } else {

      value = value.toString();
    }

    return append
      ? params.append(key, value)
      : params.set(key, value);
  }
}
