import { Pipe, PipeTransform } from '@angular/core';

import { matchesName } from '../shared/people/person-name';
import { Scout } from '../scouts/scouts.contracts';

@Pipe({ name: 'scoutSearch' })
export class ScoutsFilterPipe implements PipeTransform {

  transform(
    items: Scout[],
    text: string): Scout[] {

    if (!items || !text || !text.trim().length) return items;

    text = text.toLowerCase();

    try {

      console.debug('ScoutsFilterPipe.transform', items, text);

      return items
        .filter(scout => {

          const regionName = (scout.regionName || '').toLowerCase();
          const areaNames =
            scout.areas
              ? scout.areas
              .filter(a => a.name)
              .map(a => a.name.toLowerCase())
              .concat([regionName])
              : [];

          console.debug('ScoutsFilterPipe.transform', scout);

          return matchesName(scout.name, text) ||
            !!areaNames.find(areaName => areaName.startsWith(text) || areaName.indexOf(` ${text}`) >= 0);

        });

    } catch (err) {
      console.error('ScoutsFilterPipe.transform', err);

      return [];
    }
  }
}
