import { Component, Input } from '@angular/core';
import { PersonName } from './person-name';

@Component({
  selector: 'person-name',
  templateUrl: './person-name.component.html'
})
export class PersonNameComponent {

  @Input()
  name: PersonName;
}
