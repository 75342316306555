export class ScheduleSettings {
    ApiRoot: string
}

export class ScheduledJob {

    public constructor(data: any) {

        Object.assign(this,
            data,
            {
                stateOn: new Date(data.stateOn)
            });
    }

    readonly id: string;
    readonly isActive: boolean;
    readonly state: ScheduledJobStates;
    readonly stateOn: Date;
    readonly schedule: any;

    runningForMins(): number {

        if (this.state !== ScheduledJobStates.Running) return -1;

        return Math.round(
            (new Date().getTime() - this.stateOn.getTime()) / (1000 * 60));
    }
}

export enum ScheduledJobStates {
    Queued,
    Running,
    Completed,
    Errored
}