import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[clickOut]',
  host: {
    '(document:click)': 'onClick($event.target)'
  }
})
export class ClickOutDirective {
  constructor(
    private readonly elementRef: ElementRef
  ) {
  }

  @Output()
  clickOut = new EventEmitter();

  private onClick(targetElement) {

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside)
      this.clickOut.emit();

  }
}
