import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { MatrixPositionDefinitionsService } from './matrix-position-definitions.service';
import { MatrixPositionDefinition } from './matrix-position-definitions.contracts';

@Component({
  selector: 'muse-matrix-position-definition',
  templateUrl: './matrix-position-definition.component.html'
})
export class MatrixPositionDefinitionComponent implements OnChanges{

  constructor(
    readonly service: MatrixPositionDefinitionsService) { }

  @Input()
  id: number;

  model: MatrixPositionDefinition;

  ngOnChanges(changes: SimpleChanges): void {

    this.service.getAllById()
      .then(data => {

        this.model = data[this.id];
      });
  }
}
