import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TeamsService } from '../teams/teams.service';
import { Team } from './teams.contracts';

@Component({
  selector: 'muse-team',
  templateUrl: './team.component.html'
})
export class TeamComponent implements OnChanges{

  constructor(
    readonly teamsService: TeamsService) { }

  @Input()
  id: number;

  model: Team;

  ngOnChanges(changes: SimpleChanges): void {

    this.teamsService.getAllById()
      .then(data => {

        this.model = data[this.id];
      });
  }
}
