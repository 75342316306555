import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { ScoutsService } from './scouts.service';
import { Scout, JobDescription } from './scouts.contracts';
import { MuseTurnstilesComponentsContext } from '@mufc-aon/muse-turnstiles-components';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'scouts',
  templateUrl: './scouts.component.html',
  styleUrls: ['./scouts.component.scss']
})
export class ScoutsComponent implements OnDestroy {
  muse = MuseTurnstilesComponentsContext.instance;
  onAuthSubscription: Subscription;

  constructor(
    private readonly scoutsService: ScoutsService
  ) {

    this.onAuthSubscription = this.muse.auth.userSession$.pipe(
      tap(userSession => {

        if (userSession === null) {
          this.scouts = [];
          return;
        }

        scoutsService.getAll()
          .then(scouts => this.scouts = scouts);
      })
    )
      .subscribe();
  }

  scouts: Scout[];
  searchText: string;
  scoutTypeSelected = JobDescription.firstTeamScout;

  updateIsActive(scout: Scout) {

    return this.scoutsService.updateIsActive({
      scoutId: scout.id,
      isActive: !scout.isActive
    })
      .then(() => {
        scout.isActive = !scout.isActive;
      });
  }

  ngOnDestroy(): void {

    this.onAuthSubscription.unsubscribe();
  }
}
