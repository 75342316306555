import { Injectable, Injector } from '@angular/core';

import { PropertyService } from '../../shared/services/property.service';

import { ScoutsService } from '../../scouts/scouts.service';
import { MatrixPositionDefinitionsService } from '../position-definitions/matrix-position-definitions.service';
import { MatrixPlayer } from '../players/matrix-player.contract';
import { MatrixPositionDefinition } from '../position-definitions/matrix-position-definitions.contracts';

export class MatrixPosition {
  definitionId: number;
  definition: MatrixPositionDefinition;
  players: MatrixPlayer[];
}

@Injectable()
export class MatrixPositionFactory {

  constructor(
    private readonly property: PropertyService,
    injector: Injector
  ) {
    this.scoutsService = injector.get(ScoutsService);
    this.matrixPositionDefinitionsService = injector.get(MatrixPositionDefinitionsService);
  }

  private readonly scoutsService: ScoutsService;
  private readonly matrixPositionDefinitionsService: MatrixPositionDefinitionsService;


  create(data): MatrixPosition {

    const model = Object.assign(new MatrixPosition(),
      data,
      {
      });

    this.property.dependantById(model, 'definition', 'definitionId', id => this.matrixPositionDefinitionsService.itemsById[id]);

    return model;
  }
}
