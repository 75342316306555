import { Injectable, Injector } from '@angular/core';

import { PersonName } from '../shared/people/person-name';
import { PropertyService } from '../shared/services/property.service';
import { Area } from '../areas/areas.contracts';
import { AreasService } from '../areas/areas.service';

export class Scout {
  id: number;
  name: PersonName;
  isActive: boolean;
  regionName: string;
  areaIds: number[] = [];
  areas: Area[] = [];
  jobDescription: JobDescription;
}

export class UpdateScoutIsActive {
  scoutId: number;
  isActive: boolean;
}

export enum JobDescription {
  firstTeamScout,
  academyScout,
  analyst,
  coach,
  executive,
  operations,
  partTimeAcademyScout
}

@Injectable()
export class ScoutFactory {

  constructor(
    private readonly property: PropertyService,
    private readonly injector: Injector
  ) {
  }

  private areasService: AreasService;

  create(data): Scout {
    this.locateServices();

    const model = Object.assign(new Scout(),
      data,
      {
        name: Object.assign(new PersonName(), data.name)
      });

    this.property.dependant(model, m => m.areaIds, 'areas', this.getAreas.bind(this));

    return model;
  }

  private getAreas(areaIds) {
    
    return this.areasService.items.filter(area => areaIds.indexOf(area.id) >= 0);
  }

  locateServices() {
    if (this.areasService) return;

    this.areasService = this.injector.get(AreasService);
  }
}
