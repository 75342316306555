import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { CachingIdService } from '../shared/services/caching-id.service';

import { AreasSettings } from './areas.settings';
import { Area } from './areas.contracts';

const URL = '/areas';

@Injectable()
export class AreasService extends CachingIdService<Area> {

  constructor(
    @Inject('AreasSettings') private readonly settings: AreasSettings,
    private readonly http: HttpClient
  ) {

    super('areas');
  }

  protected load(): Promise<Area[]> {

      return this.http
        .get<Area[]>(
          this.settings.ApiRoot + URL
        )
        .toPromise();
  }
}
