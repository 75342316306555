import { Component } from '@angular/core';
import { State } from '../shared/state';

@Component({
  selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.scss']
})
export class NavMenuComponent {

  spin: boolean = false;

  constructor(
    private readonly state: State) {

    this.state.onSpin.subscribe((spinCount) => {

      this.spin = spinCount > 0;

      console.debug('NavMenuComponent.spin', this.spin);
    });
  }
}
