import { Injectable, Injector } from '@angular/core';

import { PropertyService } from '../../shared/services/property.service';

import { Scout, JobDescription } from '../../scouts/scouts.contracts';
import { ScoutsService } from '../../scouts/scouts.service';
import { MatrixPositionDefinition } from '../position-definitions/matrix-position-definitions.contracts';
import { MatrixPositionDefinitionsService } from '../position-definitions/matrix-position-definitions.service';
import { Player, PlayersFactory } from '../../players/players.contracts';

export class MatrixPlayerRating {
  matrixId: number;
  scoutId: number;
  scout: Scout;
  playerId: number;
  on: Date;
  age: number;
  positionDefinitionId: number;
  positionDefinition: MatrixPositionDefinition;
  sequence: number;
  level: number;
  yes: boolean;
  playerNote: string;
  playerNoteCreated: Date;
  betterThanOrEqualTo: Player;
  betterThanOrEqualToSecond: Player;
  positionalDescriptor: string;
  yaDevelopmentTime: string;
  isInjured: boolean;
  unattainable: boolean;
  matrixScoutJobDescription: JobDescription;
}

@Injectable()
export class MatrixPlayerRatingFactory {

  constructor(
    private readonly property: PropertyService,
    private readonly injector: Injector,
    private readonly playersFactory: PlayersFactory
  ) {
  }

  private scoutsService: ScoutsService;
  private matrixPositionDefinitionsService: MatrixPositionDefinitionsService;

  create(data): MatrixPlayerRating {
    this.locateServices();
    
    const model = Object.assign(new MatrixPlayerRating(),
      data,
      {
        on: new Date(data.on),
        betterThanOrEqualTo: data.betterThanOrEqualTo != null ? this.playersFactory.create(data.betterThanOrEqualTo) : null,
        betterThanOrEqualToSecond: data.betterThanOrEqualToSecond != null ? this.playersFactory.create(data.betterThanOrEqualToSecond) : null,
        playerNoteCreated: data.playerNoteCreated != null ? new Date(data.playerNoteCreated) : null
      });

    this.property.dependantById(model, 'scout', 'scoutId', id => this.scoutsService.itemsById[id]);
    this.property.dependantById(model, 'positionDefinition', 'positionDefinitionId', id => this.matrixPositionDefinitionsService.itemsById[id]);

    return model;
  }

  locateServices() {
    if (this.scoutsService) return;

    this.scoutsService = this.injector.get(ScoutsService);
    this.matrixPositionDefinitionsService = this.injector.get(MatrixPositionDefinitionsService);
  }
}
