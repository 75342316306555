import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[left],[right]',
  host: {
    '(touchstart)': 'onStart($event)',
    '(touchend)': 'onEnd($event)'
  }
})
export class SwipeDirective {
  constructor(
    private readonly elementRef: ElementRef
  ) {
  }

  @Output()
  left = new EventEmitter();

  @Output()
  right = new EventEmitter();

  private start: { x?: number,y?: number,time: number };

  private onStart(e) {

    this.start = { time: e.timeStamp };

    e = this.getEvent(e);
    this.start.x = e.pageX;
    this.start.y = e.pageY;
  }

  private onEnd(e) {

    if (!this.start) return;

    const dt = e.timeStamp - this.start.time;

    e = this.getEvent(e);
    const dx = e.pageX - this.start.x;

    if (dt < 500) {

      if (Math.abs(dx) > 60) {

        if (dx < 0) this.left.emit();
        else this.right.emit();
      }
    }

    this.start = null;
  }

  private getEvent(e) {

    return e.touches ? e.touches[0] || e.changedTouches[0] : e;
  }
}
