import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class State {

  spinCount: number = 0;
  onSpin: EventEmitter<number>;

  constructor() {

    this.onSpin = new EventEmitter<number>();
  }

  spin<T>(action?: () => Promise<T>): Promise<T> {
    
    this.spinCount++;
    this.onSpin.emit(this.spinCount);

    console.debug('State.spin', this.spinCount);

    if (action) {

      return action()
        .finally(()=> this.done());
    }
  }

  done(): void {
    if (this.spinCount === 0) return;
    this.spinCount--;
    this.onSpin.emit(this.spinCount);

    console.debug('State.done', this.spinCount);
  }
}
