import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AreasService } from './areas.service';
import { AreaComponent } from './area.component';
import { AreasSettings } from './areas.settings';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([], {}),
    SharedModule
  ],
  exports: [
    AreaComponent
  ],
  declarations: [
    AreaComponent
  ],
  providers: []
})
export class AreasModule {

  static forRoot(settings: AreasSettings): ModuleWithProviders<AreasModule> {

    return {
      ngModule: AreasModule,
      providers: [
        AreasService,
        { provide: 'AreasSettings', useValue: settings }
      ]
    };
  }
}
