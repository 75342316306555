import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { CachingIdService } from '../shared/services/caching-id.service';

import { ScoutsSettings } from './scouts.settings';
import { Scout, ScoutFactory, UpdateScoutIsActive } from './scouts.contracts';
import { MuseTurnstilesComponentsContext } from '@mufc-aon/muse-turnstiles-components';
import { tap } from 'rxjs/operators';

const SCOUTS_URL = '/scouts';
function SCOUTS_BY_ID_URL(id: number) { return `${SCOUTS_URL}/${id}`; }
function SCOUTS_UPDATE_IS_ACTIVE_URL(id: number) { return `${SCOUTS_BY_ID_URL(id)}/is-active`; }

@Injectable()
export class ScoutsService extends CachingIdService<Scout> {

  muse = MuseTurnstilesComponentsContext.instance;

  constructor(
    @Inject('ScoutsSettings') private readonly settings: ScoutsSettings,
    private readonly http: HttpClient,
    private readonly scoutFactory: ScoutFactory
  ) {

    super('scouts');

    this.muse.auth.userSession$.pipe(
      tap(userSession => {

        if (userSession == null) {
          this.clear();
          return;
        }

        this.getAll();
      })
    )
      .subscribe();
  }

  protected load(): Promise<Scout[]> {

    return this.http
      .get<Scout[]>(
        this.settings.ApiRoot + SCOUTS_URL
      )
      .toPromise()
      .then(data => {

        return data.map(d => this.scoutFactory.create(d));
      });
  }

  updateIsActive(model: UpdateScoutIsActive) {

    return this.http
      .put(
        this.settings.ApiRoot + SCOUTS_UPDATE_IS_ACTIVE_URL(model.scoutId),
        model)
      .toPromise();
  }
}
