import { Pipe, PipeTransform } from '@angular/core';

import { matchesName } from '../shared/people/person-name';

import { ScoutsService } from '../scouts/scouts.service';

import { Matrix } from './matrix.contract';
import { Scout, JobDescription } from '../scouts/scouts.contracts';

@Pipe({ name: 'matrixSearch' })
export class MatrixSearchFilterPipe implements PipeTransform {

  private scoutsById: { [id: number]: Scout };

  constructor(
    private readonly scoutsService: ScoutsService
  ) {

    this.scoutsService.getAllById()
      .then(data => {

        this.scoutsById = data;
      });
  }

  transform(
    items: Matrix[],
    text: string): Matrix[] {

    if (!text || !text.trim().length) return items;

    text = text.toLowerCase();

    try {

      console.debug('MatrixSearchFilterPipe.transform', text);

      return items
        .filter(item => {

          const scout = this.scoutsById[item.scoutId];
          if (!scout) {
            return false;
          }

          const regionName = (scout.regionName || '').toLowerCase();
          const areaNames = scout.areas
            .filter(a => a.name)
            .map(a => a.name.toLowerCase())
            .concat([regionName]);

          return matchesName(scout.name, text) ||
            !!areaNames.find(areaName => areaName.startsWith(text) || areaName.indexOf(` ${text}`) >= 0);

        });

    } catch (err) {

      return [];
    }
  }
}

@Pipe({ name: 'matrixPlayerLevel' })
export class MatrixPlayerLevelPipe implements PipeTransform {

  transform(value: any, scout: Scout) {

    if (scout.jobDescription == JobDescription.firstTeamScout) {
      switch (value) {
        default: return 'U';
        case 0: return 'A';
        case 1: return 'YA';
        case 2: return 'B';
        case 3: return 'F';
      }
    } else {
      switch (value) {
        default: return 'U';
        case 0: return 'A*';
        case 1: return 'B';
        case 2: return 'A';
        case 3: return 'C';
        case 4: return 'D';
      }
    }
  }
}
