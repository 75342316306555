import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AreasModule } from '../areas/areas.module';

import { TeamsSettings } from './teams.settings';
import { TeamsService } from './teams.service';
import { TeamComponent } from './team.component';
import { TeamsFactory } from './teams.contracts';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([], {}),
    SharedModule,
    AreasModule
  ],
  exports: [
    TeamComponent
  ],
  declarations: [
    TeamComponent
  ],
  providers: []
})
export class TeamsModule {

  static forRoot(settings: TeamsSettings): ModuleWithProviders<TeamsModule> {

    return {
      ngModule: TeamsModule,
      providers: [
        TeamsService, TeamsFactory,
        { provide: 'TeamsSettings', useValue: settings }
      ]
    };
  }
}
