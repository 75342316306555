import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AuthHttpInterceptor } from './auth.http-interceptor';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot([], {})
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class AuthModule {

    static forRoot(): ModuleWithProviders<AuthModule> {

        return {
            ngModule: AuthModule,
            providers: [
                { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
            ]
        };
    }
}
