import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { CachingIdService } from '../../shared/services/caching-id.service';

import { MatricesSettings } from '../matrices.settings';
import { MatrixPositionDefinition } from './matrix-position-definitions.contracts';

const URL = '/matrices/position-definitions';

@Injectable()
export class MatrixPositionDefinitionsService extends CachingIdService<MatrixPositionDefinition> {

  constructor(
    @Inject('MatricesSettings') private readonly settings: MatricesSettings,
    private readonly http: HttpClient
  ) {

    super('matrixPositionDefinitions');
  }

  protected load(): Promise<MatrixPositionDefinition[]> {

      return this.http
        .get<MatrixPositionDefinition[]>(
          this.settings.ApiRoot + URL
        )
        .toPromise();
  }
}
