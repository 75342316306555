import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AreasModule } from '../areas/areas.module';

import { ScoutsSettings } from './scouts.settings';
import { ScoutsService } from './scouts.service';
import { ScoutsComponent } from './scouts.component';
import { ScoutComponent } from './scout.component';
import { ScoutFactory } from './scouts.contracts';
import { ScoutsFilterPipe } from './scouts.pipes';

const ROUTES = [
  { path: 'scouts', component: ScoutsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    SharedModule,
    AreasModule
  ],
  exports: [
    ScoutsComponent, ScoutComponent
  ],
  declarations: [
    ScoutsComponent, ScoutComponent,
    ScoutsFilterPipe
  ],
  providers: []
})
export class ScoutsModule {

  static forRoot(settings: ScoutsSettings): ModuleWithProviders<ScoutsModule> {

    return {
      ngModule: ScoutsModule,
      providers: [
        ScoutsService, ScoutFactory,
        ScoutsFilterPipe,
        { provide: 'ScoutsSettings', useValue: settings }
      ]
    };
  }
}
